import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about.vue')
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: () => import('../views/insurance.vue')
  },
  {
    path: '/technology',
    name: 'technology',
    component: () => import('../views/technology.vue')
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: () => import('../views/articleList.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
