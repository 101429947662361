<template>
    <div>
        <footer class="footer">
            <div class="footer-top">
                <div class="w clearfix">
                    <div class="concat fl">
                        <div class="logo">
                            <!-- <img style="width: 200px; height: 50px;" src="../../assets/img/pic_logo.png" /> -->
                        </div>
                        <!-- <p>客服热线</p>
                        <p class="time">工作日 9:00-18:00</p>
                        <p>4000-150-999</p> -->
                    </div>
                    <div class="footerNav fl clearfix">
                        <div class="item fl">
                            <h3>
                                <router-link to="/articleList">
                                 信息咨询
                                </router-link>
                            </h3>
                            <ul>
                                <li >

                                    <a >公司介绍</a>
                                </li>
                                <li >
                                    <a >核心团队</a>
                                </li>
                                <li >
                                    <a >公司荣誉</a>
                                </li>
                            </ul>
                        </div>
                        <div class="item fl">
                            <h3>
                                <router-link to="/technology">数字产品</router-link>
                            </h3>
                            <ul>
                                <li >
                                    <a >产品介绍</a>
                                </li>
                                
                                <li >
                                    <a >解决方案</a>
                                </li>
                                <li >
                                    <a >主营业务</a>
                                </li>
                            </ul>
                        </div>
                        <div class="item fl">
                            <h3>
                                <router-link to="/insurance">机构介绍</router-link>
                            </h3>
                            <ul>
                                <li >
                                    <a>团队职能</a>
                                </li> 
                                <li >
                                    <a>业务能力</a>
                                </li>
                                 <li >
                                    <a >服务资质</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="down fr">
                        <!-- <h3>公众号</h3> -->
                        <div class="code clearfix">
                            <div class="item fl">
                                <!-- <img style="width: 40px; height: 40px;" src="../../assets/img/xiaoyu.jpg" />
                                <p>小鱼向上</p> -->
                            </div>
                            <!-- <div class="item fl">
                                <img style="height: 120px; height: 120px;" src="../../assets/img/qrcode_for_gh_51748f40af4d_258.jpg" />
                                <p>小鱼网厅</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-partner">
                <div class="w clearfix">
                    <h3 class="f-tit fl">合作伙伴</h3>
                    <div class="partnerList">
                        <span>中国人保保险</span>
                        <span>中国人寿财险</span>
                        <span>太平洋保险</span>
                        <span>中国平安保险</span>
                        <span>QQ音乐</span>
                        <span>网易云音乐</span>
                        <span>爱奇艺</span>
                        <span>腾讯视频</span>
                        <span>芒果TV</span>
                        <span>知乎</span>
                        <span>豆瓣</span>
                        <span>咪咕音乐</span>
                        <span>饿了么</span>
                        <span>美团外卖</span>
                        <span>必胜客</span>
                        <span>bilibili</span>
                        <span>奈雪的茶</span>
                        <span>曹操出行</span>
                        <span>叮咚买菜</span>
                        <span>滴滴出行</span>
                        <span>e袋洗</span>
                        <span>携程</span>
                        <span>酷狗音乐</span>
                        <span>知识付费</span>
                        <span>微信读书</span>
                        <span>樊登读书</span>
                        <span>百度网盘</span>
                        <span>QQ阅读</span>
                        <span>酷我音乐</span>
                        <span>DQ</span>
                        <span>喜茶</span>
                        <span>哈根达斯</span>
                        <span>麦当劳</span>
                        <span>肯德基</span>
                        <span>瑞幸</span>
                        <span>讯飞</span>
                    </div>
                </div>
            </div>
            <div class="copyRight">
                <div class="w">
                    <p>
                        杭州向上信息网络有限公司 
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备11062741号</a>
                        <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102003824">
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAABm1BMVEVHcEzv2pHjtFvlv2Hqw47135jt3Jfx47DpvljnwXzqyG3x34XlzJPZsYLhrmPmsVXy4pTpzX+xi2jz1mrfr2WujXXoyXS4knDWuovkwGHhrk/UmFzfpVLbr2baoFzqwXPQkEjcpk7dolDZnFnisF7mxoHrz23z3Yfs0Gnmx4Cda1Kgb0bTuJGgeGSthlqmZDnt0Jd2XF6uhmF5ZGu/m3J7ZWfOtXt5ZmvhrWvkrV7qxG323ZDNwH/jpUrns1HWIxTnt1XelkXgnUjrwVzru1TTKhraUifdcjlRT3j1zVzZXi6fj28cGWjJSCLNkVTowmrdgj5iXXDpxmXwulGYWzXZLBnQrFjUHRbaGA7HVyerJSPnaDHUazLXfT3HqmRYRmQhIW9fW4DGo2Y2FGDIrnHdjEVwaYFBO3FwTUqRaE3XQSOvoGtfMVfNOR3HdTylh2HYkUBvO1KQEiFOQGPOHxVzUlLQZTB0CC2kNjfgIw/REwmIIj1+e4dkBD96OUUXJoE8RYblJxiYjX+JSU+XkH14c3bMpFs4QXtsBDmu5RCHAAAAVXRSTlMAJv7zEBQLA/4ZubI2LdnkST6b/RzJxp9m+f31+afkspHD1VDLgu1T/G7j/W/m+fhz7Jj9j+hd98R904ij//////////7////////////////////+VRnNpwAAATlJREFUGNM10GWPwlAQBdBHqeIOIbDu7l6nxd2luKy7u+/+7O2ScD+ezExuBgA5GKx1WK0zWhgDvWhMU/Op6blZu6YnhGk3lReLkZOrJaQLmMK+uBeghX1aCq6uKP53UcfyxXGapoVLIRJfX0Nl0rp9letcNi1k76W4b0eDAXTTVatIuSOJDdCtuE+5jQLYzbU7kXy4FPPGvjttph8Glj72/eXZK4YDovej+knqLDK16q9Pd2K4VHz7/WlyOgIQG2T0sXFzXijcPjS+okw/AggdSzVr1dDhWSjkK3ucW/KUzcVyUPkgkwkG6yRHqRCAGVTOU9bvTyb9ZpKDVEa5PrKgZEicx3megqLKSVhur1CbExDO8AxJ4okJtUImeNymxD2Uh2JwfExtQLuvsAwPDA7p9SOqUSMiX/oDUSM/4uOnYRoAAAAASUVORK5CYII=" />
                            京公网安备 11010102003824号
                        </a> -->
                    </p>
                </div>
            </div>
            <div class="go-w">
                <a class="goUp"></a>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },

    mounted() { },

    methods: {
        handleClickJump() {
            this.$emit('clickJump');
        }
    }
};
</script>

<style lang="scss" scoped>
.footer {
    background: #353a3e;
}

.footer,
.footer a {
    color: #b0b2b4;
}

.footer .footer-top {
    padding: 60px 0 30px;
    border-bottom: 1px solid #474c4f;
}

.footer .footer-top .concat {
    width: 280px;
    font-size: 20px;
}

.footer .footer-top .concat p {
    color: #b0b2b4;
}

.footer .footer-top .concat .time {
    font-size: 14px;
    margin-bottom: 30px;
}

.footer .footer-top .logo {
    margin-bottom: 30px;
}

.footer .down,
.footer .footerNav {
    padding-top: 20px;
    margin-right: 60px;
}

.footer .down .code .item p,
.footer .down h3,
.footer .footerNav {
    color: #b0b2b4;
}

.footer .footerNav a:hover {
    color: #fff;
}

.footer .footerNav .item {
    margin-right: 80px;
}

.footer .footerNav .item h3 {
    margin-bottom: 33px;
}

.footer .footerNav .item li {
    margin-bottom: 20px;
}

.footer .down .code {
    margin-top: 11px;
}

.footer .down .code p {
    font-size: 14px;
    margin-top: 14px;
}

.footer .down .code .item {
    margin-right: 38px;
}

.footer .down .code .item:last-child {
    margin-right: 0;
}

.footer .footer-partner {
    padding-top: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #474c4f;
}

.footer .footer-partner .f-tit {
    color: #b0b2b4;
}

.footer .footer-partner .partnerList {
    margin-left: 250px;
    font-size: 0;
}

.footer .footer-partner .partnerList span {
    font-size: 14px;
    display: inline-block;
    width: 150px;
    text-align: left;
    border-right: 1px solid #b0b2b4;
    margin-bottom: 15px;
    padding-left: 30px;
    line-height: 20px;
    box-sizing: border-box;
    color: #b0b2b4;
}

.footer .footer-partner .partnerList span:nth-child(6n) {
    border-right: none;
}

.footer>.copyRight {
    padding-top: 42px;
    padding-bottom: 53px;
    font-size: 14px;
    line-height: 20px;
}

.footer .copyRight p {
    color: #b0b2b4;
}

.footer .copyRight a {
    padding-left: 20px;
}

.footer .copyRight a img {
    width: 18px;
    height: 18px;
    vertical-align: top;
}

.go-w {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.goUp {
    width: 76px;
    height: 76px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMBAMAAAA1uUwYAAAAGFBMVEVHcEz///81Oj5tcHNKTlL19fXg4eLS09Q9uEE+AAAAAXRSTlMAQObYZgAAAHpJREFUSMdjYBhJQIkAgCpTFMQLhEaVjSobVTbMlQkbEqXMSJkYZcFKSqaElYkaqRcpBxJUFqxSKO5kSkiZqJG7oGAJinHYlAWrJAoKohqHRZmokxuISlEJxKtMGGSYoKCYkyFeZWJmEDo5cTS9jSobVTaqjMiG2cgAALVyl+E+y+jZAAAAAElFTkSuQmCC) no-repeat;
    position: absolute;
    right: -76px;
    bottom: 486px;
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.51, 0.01, 0, 1);
    cursor: pointer;
}

.goUp.on {
    opacity: 1;
}

.goUp:hover {
    width: 76px;
    height: 76px;
    background: url('../../assets/img/goUp-on.d181f88d.png') no-repeat;
    position: absolute;
    right: -76px;
    bottom: 486px;
    cursor: pointer;
}

.footerEN .footer-top .concat {
    font-size: 16px;
}

.footerEN .footer-top .concat .time {
    font-size: 12px;
    margin-bottom: 30px;
}

.footerEN .footerNav .item {
    margin-right: 48px;
}

.footerEN .footerNav .item:last-child {
    margin-right: 30px;
}

.footerEN .footerNav .item li a {
    font-size: 12px;
}

.footerEN .footer-partner .partnerList span {
    padding-left: 10px;
}
</style>